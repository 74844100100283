import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import UserDropdown from './UserDropdown';
import LeadDropdown from './LeadDropdown';

class TopMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            unique: Date.now(),
            selectedValue: ''
        }
    }

    render() {
        return (
            <nav className="navbar navbar-subnav navbar-expand-sm navbar-dark bg-primary">
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item active">
                            <Link className="nav-link" to="/admin"><i className="zmdi zmdi-view-dashboard"></i> Dashboard</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <UserDropdown />
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/admin/products" >
                                <i className="zmdi zmdi-device-hub"></i> Products
                            </Link>
                        </li>
                        <li className="nav-item dropdown">
                            <LeadDropdown />
                        </li>
                    </ul>
                </div>
            </nav>
        )
    }
}
export default TopMenu;