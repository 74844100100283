import React from 'react';
import { FormRow } from '../../Theme';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

function ViewModal(props) {
    var myRef = React.useRef();
    let { showViewModal, employee } = props;

    function closeModal() {
        props.toggleViewModal('');
    }

    return (<>
        <div ref={myRef} />
        <Modal modalId="noti-modal"
            open={showViewModal}
            onClose={closeModal}
            center
            container={myRef.current}>
            <div className="">
                <div className="modal-body">
                    <div className="modal-header" style={{paddingLeft: '0'}}>
                        <h4 style={{marginBottom: "0"}}>View Employee</h4>
                    </div>
                    <FormRow>
                        <div className="col-md-12">
                            Username: {employee.mobNumber}
                        </div>
                    </FormRow>
                    {employee.firstName !== '' && <FormRow>
                        <div className="col-md-12">
                            Name: {employee.firstName}
                        </div>
                    </FormRow>}
                    {employee.emailId && <FormRow>
                        <div className="col-md-12">
                            Email: {employee.emailId}
                        </div>
                    </FormRow>}
                    {employee.address && <FormRow>
                        <div className="col-md-12">
                            Address: {employee.address}
                        </div>
                    </FormRow>}
                    {employee.addressLine1 && <FormRow>
                        <div className="col-md-12">
                            Address Line 1: {employee.addressLine1}
                        </div>
                    </FormRow>}
                    {employee.addressLine2 && <FormRow>
                        <div className="col-md-12">
                            Address Line 2: {employee.addressLine2}
                        </div>
                    </FormRow>}
                    {employee.city && <FormRow>
                        <div className="col-md-12">
                            City: {employee.city}
                        </div>
                    </FormRow>}
                    {employee.state && <FormRow>
                        <div className="col-md-12">
                            State: {employee.state}
                        </div>
                    </FormRow>}
                </div>
            </div>
        </Modal>
    </>)
}
export default ViewModal;