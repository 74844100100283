import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';

class Meta extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            description: this.props.description
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.title !== this.props.title) {
            this.setState({ title: this.props.title });
        }
        if(prevProps.description !== this.props.description) {
            this.setState({ description: this.props.description });
        }
    }

    render() {
        let { title, description } = this.state;
        return (
            
                <Helmet>
                    <title>{title? title: 'Job Fair Application'}</title>
                    {description? <meta name="description" content={description} />:
                        <meta name="description" content="job-fair-application" />}
                </Helmet>
        )
    }
}
export default Meta;