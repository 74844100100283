import React, { Component } from 'react';
import { Ajax } from '../../api';
import ListItem from './ListItem';
import { Paginate, Row, Input } from '../../Theme';
import NotiModal from './NotiModal';
import SendToAll from './SendToAll';
import ViewModal from './ViewModal';
import Meta from '../../Layout/Meta'
import ExcelExport from './ExcelExport'
import { isEmpty } from '../../helpers'
import DatePicker from 'react-date-picker';

class Employers extends Component {
    _isMounted = false;
    employers = []

    constructor(props) {
        super(props)
        this.state = {
            employers: [],
            totalRecords: 0,
            loading: false,
            currentPage: 1,
            showNotiModal: false,
            showNotiAllModal: false, 
            employer: {},
            showViewModal: false,
            searchText: '',
            origCount: 0,
            exportData: []
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.getEmployers(1, '', '', false);
    }
    componentWillUnmount = () => this._isMounted = false;
    setState = (state, callback) => this._isMounted && super.setState(state, callback);

    getEmployers(pageNumber, startDate, endDate, getExport) {
        let params = { pageNumber, recordCount, startDate, endDate };
        Ajax.get('employer/admin/getcompaniesbydate', params)
            .then(data => {
                if(data.status && data.status === 'OK' &&!isEmpty(data.content)) {
                    this.employers = data.content.companyList
                    this.setState({ loading: false, totalRecords: data.content.count, origCount: data.content.count,
                        employers: data.content.companyList})
                    if(getExport) {
                        this.getExportableData(data.content.count)
                    }
                    return;
                } else {
                    this.setState({ loading: false, totalRecords: 0, origCount: 0,
                        employers: []})
                }
                this.setState({ loading: false });
            }).catch(() => this.setState({ loading: false }));
    }

    getExportableData(count) {
        let { startDate, endDate } = this.getFormattedDate()
        let params = { pageNumber: 1, recordCount: count, startDate, endDate };
        Ajax.get('employer/admin/getcompaniesbydate', params)
            .then(data => {
                if(data.status && data.status === 'OK') {
                    this.setState({ exportData: data.content.companyList })
                    return;
                }
            }).catch(e => e);
    }

    onPageChanged = data => {
        const { currentPage } = data;
        let { startDate, endDate } = this.getFormattedDate()
        this.getEmployers(currentPage, startDate, endDate, false);        
    }

    updateStatus = index => {
        let { employers } = this.state;
        employers[index].companyStatus = !employers[index].companyStatus;
        this.setState({ employers });
    }

    toggleNotiModal = (index) => {
        let { employers, employer } = this.state;
        var show = false;
        if(index === '') {
            employer = {};
            show = false;
        } else {
            employer = employers[index];
            show = true;
        }
        this.setState({ showNotiModal: show, employer });
    }

    toggleNotiAllModal = (index) => {
        var show = false;
        if(index === '') {
            show = false;
        } else {
            show = true;
        }
        this.setState({ showNotiAllModal: show });
    }

    filterRows = (name, value) => {
        let employers = this.employers.filter(e => e.mobNumber.includes(value) || e.companyName.toLowerCase().includes(value));
        this.setState({ employers, searchText: value, totalRecords: employers.length })
        if(value === '') {
            this.setState({ totalRecords: this.state.origCount });
        }
    }

    toggleViewModal = (index) => {
        let { employers, employer } = this.state;
        var show = false;
        if(index === '') {
            employer = {};
            show = false;
        } else {
            employer = employers[index];
            show = true;
        }
        this.setState({ showViewModal: show, employer });
    }

    startDateChange = (value) => {
        this.setState({ startDate: value })
    }

    endDateChange = (value) => {
        this.setState({ endDate: value })
    }

    filterRecords = () => {
        let { startDate, endDate } = this.getFormattedDate()
        this.getEmployers(1, startDate, endDate, true);
    }

    getFormattedDate() {
        let { startDate, endDate } = this.state
        if(!startDate ) {
            return {startDate: '', endDate: ''}
        }
        let sd = new Date(startDate)
        let sdf = `${sd.getFullYear()}-${sd.getMonth() + 1}-${sd.getDate()}`
        let ed = new Date(endDate)
        let edf = `${ed.getFullYear()}-${ed.getMonth() + 1}-${ed.getDate()}`
        return { startDate: sdf, endDate: edf }
    }

    render() {
        let { employers, loading, totalRecords, currentPage, employer, showNotiAllModal, 
            searchText, showViewModal, startDate, endDate, exportData } = this.state;
        return (<>
            <Meta title="Employers" />
            <div className="main">
                <div className="main-inner tf_section_wrapper tf_widgets_EmployeeAttendance">
                    <div className="container">
                        <Row>
                            <div className="col-sm-12">
                                <h3>Employers</h3>
                            </div>
                            <div className="col-sm-6">
                                <Input value={searchText} onChange={this.filterRows} placeholder="Search" />
                            </div>
                            <div className="col-sm-6 text-right" style={{paddingBottom: '5px'}}>
                                <button className="btn btn-primary" onClick={() => this.setState({ showNotiAllModal: true })}>
                                    Send Notifications to All Employers
                                </button>
                            </div>
                            <div className="col-sm-3" style={{paddingBottom: '5px'}}>
                                <DatePicker className="date-input"
                                    onChange={this.startDateChange} showLeadingZeros={true}
                                    value={startDate} closeCalendar={true} />
                            </div>
                            <div className="col-sm-3" style={{paddingBottom: '5px'}}>
                                <DatePicker className="date-input"
                                    onChange={this.endDateChange}
                                    value={endDate} showLeadingZeros={true}
                                    minDate={startDate || new Date()} />
                            </div>
                            {(startDate && endDate) && <div className="col-sm-3" style={{paddingBottom: '5px'}}>
                                <button className="btn btn-primary mr-10" onClick={this.filterRecords}>
                                    Filter
                                </button>
                                {!isEmpty(exportData) && <ExcelExport exportData={exportData} startDate={startDate} endDate={endDate} />}
                                <button className="btn btn-primary" onClick={this.clearRecords}>
                                    Clear
                                </button>
                            </div>}
                        </Row>
                        {loading? <h1>Loading...</h1>:<>
                            <Row>
                                <div className="col-sm-12">
                                    <table className="table table-hovered table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Username</th>
                                                <th>Email</th>
                                                <th>City</th>
                                                <th>State</th>
                                                <th>Date</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {employers.map((emp, i) => (
                                                <ListItem key={i} index={i} employer={emp} 
                                                    updateStatus={this.updateStatus.bind(this)}
                                                    toggleNotiModal={this.toggleNotiModal.bind(this)} 
                                                    toggleViewModal={this.toggleViewModal.bind(this)} />
                                            ))}

                                                {
                                                employers.length === 0 && (
                                                     <tr>
                                                        <th colSpan={4} style={{textAlign:'center'}}>
                                                            No data found!
                                                        </th>
                                                     </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </Row>
                            {totalRecords > recordCount &&
                                <Row>
                                    <div className="col-sm-12">
                                        <div className="text-right" style={{float: 'right'}}>
                                            <Paginate
                                                totalRecords={totalRecords}
                                                pageLimit={recordCount}
                                                pageNeighbours={2}
                                                currentPage={currentPage}
                                                showStats={true}
                                                onPageChanged={this.onPageChanged} />
                                        </div>
                                    </div>
                                </Row>}
                        </>}
                    </div>
                </div>
            </div>
            <NotiModal showNotiModal={this.state.showNotiModal} employer={employer}
                toggleNotiModal={this.toggleNotiModal.bind(this)} />
            <SendToAll showNotiModal={showNotiAllModal}
                toggleNotiAllModal={this.toggleNotiAllModal.bind(this)} />
            <ViewModal showViewModal={showViewModal} employer={employer}
                toggleViewModal={this.toggleViewModal.bind(this)} />
        </>)
    }
}
var recordCount = 10;
export default Employers;