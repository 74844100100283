import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import '../../styles/confirm-alert.css';
import { Ajax } from '../../api';

export default function ListItem(props) {

    let { employee, index } = props;

    function confirmStatus() {
        let m = employee.employeeStatus? 'Disable': 'Enable';
        confirmAlert({
            title: `Do you want to ${m} this employee?`,
            message: '',
            buttons: [
                {
                    label: 'No',
                    className: 'btn btn-secondary'
                },
                {
                    label: 'Yes',
                    onClick: changeStatus,
                    className: 'btn btn-primary'
                }
            ]
        });
    }

    function changeStatus() {
        let status = employee.employeeStatus? 'False': 'True';
        let params = { id: employee.id, status }
        Ajax.call('employee/admin/manageemployee', params)
            .then(data => {
                props.updateStatus(index);
            }).catch(e => e)
    }

    function showNotiModal() {
        props.toggleNotiModal(index);
    }

    function showViewModal() {
        props.toggleViewModal(index);
    }

    return (
        <tr className={employee.employeeStatus? '': 'disabled-row'}>
            <td>
                <span style={{cursor: 'pointer'}} onClick={showViewModal}>
                    {employee.mobNumber} {employee.firstName && employee.firstName !== ''? ` - ${employee.firstName}`: ``}
                </span>
            </td>
            <td>{employee.emailId}</td>
            <td>{employee.city}</td>
            <td>{employee.state}</td>
            <td>{employee.createdDatetime}</td>
            <td>
                {employee.employeeStatus? <button className="btn btn-primary mr-10" title="Enable" onClick={confirmStatus}>
                    <i className="fa fa-check-square-o" aria-hidden="true"></i>
                </button>: <button className="btn btn-danger mr-10" title="Disable" onClick={confirmStatus}>
                    <i className="fa fa-ban" aria-hidden="true"></i>
                </button>}
                {employee.employeeStatus && <button title="Send Notification" className="btn btn-warning" onClick={showNotiModal}>
                    <i className="fa fa-bell" aria-hidden="true"></i>
                </button>}
            </td>
        </tr>
    )
}