export const setUser = (user) => {
    localStorage.setItem("adminUser", JSON.stringify({
        username: user.username, lang: user.lang, role: user.role, ba: user.ba
    }));
}

export const getUser = () => JSON.parse(localStorage.getItem("adminUser"))

export const removeUser = () => localStorage.removeItem("adminUser")

export const setSessionExpire = () => localStorage.setItem("jfa_timestamp", new Date().getTime());

export const checkSessionExpire = () => {
    let ts = localStorage.getItem("jfa_timestamp");
    let now = new Date().getTime();

    var diff = (now - ts) / 60000;
    let { location } = window;
    let url = location.href;

    if(diff > 60 && url.search('admin') > 0) {
        removeUser();
        setSessionExpire();
        window.location.href = '/';
    }
}