import React,{ Component } from 'react';
import Meta from '../Layout/Meta';
import { setUser, setSessionExpire } from '../Auth/user';
import validate from '../lib/validate';
import { isEmpty } from '../helpers';
import { Ajax } from '../api';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import "../index.css";
import logo from "../assets/images/logo.png";


class Login extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            loading: false,
            redirectTo: ''
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount = () => this._isMounted = false;
    setState = (state, callback) => this._isMounted && super.setState(state, callback);

    setFieldState = e => {
        let { fields, errors } = this.state;
        let { name, value } = e.target;
        fields[name] = value;
        delete errors[name];
        this.setState({ fields, errors });
    }

    handleSubmit = e => {
        e.preventDefault();
        let { fields } = this.state;
        let errors = this.validate();
        if(!isEmpty(errors)) {
            return false;
        }
        // setUser({username: fields.username});
        // setSessionExpire();
        // this.setState({ redirectTo: '/admin' });
        // return

        let params = {
            username : fields.username,
            password : fields.password
        }
        this.setState({ loading: true });
        toast.clearWaitingQueue();
        let url = 'admin/login';
        Ajax.get(url, params, 'GET')
            .then(data => {
                if(data.status && data.status === 'OK') {
                    setUser({username: fields.username});
                    setSessionExpire();
                    this.setState({ redirectTo: '/admin' });
                } else if(data.message) {
                    toast.error(data.message);
                }
                this.setState({ loading: false });
            }).catch((e) => {
                this.setState({ loading: false });
            });
    }

    validate() {
        let rules = this.rules();
        let errors = validate(this.state.fields, rules);
        this.setState({ errors });
        return errors;
    }

    rules() {
        return {
            username: {required: true},
            password: {required: true}
        }
    }

    render() {
        let { errors, fields, redirectTo } = this.state;
        if(redirectTo) {return <Redirect to={redirectTo} />}
        return (<>
            <Meta title="Login" />
           
            <div className="tf_login_bg">
                <span></span>
            </div>
            
            <div className="container h-100">
                <div className="row align-items-center h-100">
                    <div className="col-md-4 mx-auto">
                    
                        <div className="tf_login_wrapper mb-3">
                            <div className="tf_login_head text-center" style={{marginTop: '10px'}}>
                                <a className="brand" href="http://admin.jobkashop.com/">
                                    <img src={logo} alt="" style={{width: '100px'}}/>
                                </a>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <h4>Login</h4>
                                <p className="mb-0">Please provide your details</p>
                            </div>
                            <div className="card-body pt-3">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="login-fields">
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1"><i className="zmdi zmdi-account-o"></i></span>
                                            </div>
                                            <input type="text" name="username" placeholder="Username" value={fields.username || ''}
                                                className={`login username-field form-control ${errors.username? 'has-error': ''}`} onChange={this.setFieldState} required/>
                                        </div>
                                        
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1"><i className="zmdi zmdi-lock-outline"></i></span>
                                            </div>
                                            <input type="password" name="password" placeholder="Password" value={fields.password || ''}
                                                className={`login password-field form-control ${errors.password? 'has-error': ''}`} onChange={this.setFieldState} required/>
                                        </div>
                                        <div className="login-actions d-flex mt-4 align-items-cnter">
                                            <button className="button btn btn-primary btn-large tf_btn mr-3">Sign In</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
}
export default Login;