import React, { Component } from 'react';
import { LoadingButton, Number, Form, FormRow } from '../../Theme';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { Ajax } from '../../api/';
import { toast } from 'react-toastify';

class AssignFreeLeads extends Component {
    _isMounted = false;

    id= "";
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            errors: {},
            leadCount: '',
            submitted: false,
            otp: ""
        }
        this.myRef = React.createRef()
    }

    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount = () => this._isMounted = false;
    setState = (state, callback) => this._isMounted && super.setState(state, callback);

    closeModal = () => {
        this.setState({ message: '', otp: "", submitted: false }, () => {
            this.props.toggleFreeModal('');
        });
    }

    assignLeads = e => {
        e.preventDefault();
        let { leadCount } = this.state;
        let { selectedIds } = this.props;
        if(leadCount === '') {
            this.setState({ errors: {leadCount: true}});
            return false;
        }
        this.setState({ errors: {}, loading: true });
        let params = { leadCount, id: selectedIds.join(',') }
        Ajax.call('employee/admin/assignfreeleadsforemployee', params)
            .then(data => {
                if(data.status && data.status === "OK") {
                    this.id = data.content.id;
                    //toast.success(data.message);
                    
                    this.setState({ submitted: true });
                   // this.props.toggleFreeModal('');
                    
                } else if(data.message) {
                    toast.error(data.message);
                    this.props.toggleFreeModal("");
                }
                this.setState({ loading: false });
            }).catch(() => {
                this.setState({ loading: false });
                toast.error("Something went wrong, please try again later.");
            })
    }

assignLeadsSubmitOtp = (e) => {
    e.preventDefault();
    
    let { otp } = this.state;
    let { selectedIds } = this.props;

    // if (leadCount === "") {
    //   this.setState({ errors: { leadCount: true } });
    //   return false;
    // }
    this.setState({ errors: {}, loading: true });
    //let params = { otp, id: selectedIds.join(",") };
    Ajax.get(
      `employee/admin/validateotp?id=${this.id}&otp=${otp}`
    )
      .then((data) => {
        if (data.status && data.status === "OK") {
          toast.success(data.message);
          this.props.toggleFreeModal("");
          this.setState({ submitted: false });
        } else if (data.message) {
          toast.error(data.message);
        }
        this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
        toast.error("Something went wrong, please try again later.");
      });
  };
    render() {
        let { loading, errors, leadCount, submitted, otp } = this.state;
        let { showFreeModal } = this.props;

        return (
        <>
            <div ref={this.myRef} />
            <Modal modalId="noti-modal"
              open={showFreeModal}
              onClose={this.closeModal}
              center
              container={this.myRef.current}>
              <div className="">
                    <div className="modal-header" style={{paddingLeft: '0'}}>
                     {submitted && "Enter OTP"}
                     {!submitted && "Assign Free Leads"}
                    </div>
                    <div className="modal-body">
                        <Form errors={errors}>
                        {!submitted && (
                            <FormRow>
                                <div className="col-md-12" style={{width: '100%'}}>
                                    <Number name="leadCount" 
                                    value={leadCount}
                                        onChange={(name, value) => this.setState({ leadCount: value })} />
                                </div>
                            </FormRow>
                            )}
                        {submitted && (
                        <FormRow>
                    <div className="col-md-12" style={{ width: "100%" }}>
                      <Number
                        name="otp"
                        value={otp}
                        onChange={(name, value) =>
                          this.setState({ otp: value })
                        }
                      />
                    </div>
                  </FormRow>
                )}
                        </Form>
                    </div>
                    <div className="modal-footer">
                        {!loading? (
                        <button 
                        type="button" 
                        className="btn btn-signup" 
                        onClick={
                    submitted ? this.assignLeadsSubmitOtp : this.assignLeads
                  }
                  >
                            Submit
                        </button>
                        ) : (
                        <LoadingButton className="btn btn-signup" />
                         )}
                    </div>
                </div>
            </Modal>
        </>
   );
  }
}
export default AssignFreeLeads;