import React from 'react';
import { Checkbox } from '../../Theme'

export default function ListItem(props) {

    let { employer, selectedIds } = props;

    function handleChecked() {
        props.selectCheckbox(employer.id, 'SINGLE');
    }

    return (
        <tr className={employer.companyStatus? '': 'disabled-row'}>
            <td className="text-center">
                <Checkbox name="select" checked={selectedIds.indexOf(employer.id) >= 0}
                    onChange={handleChecked} />
            </td>
            <td>
                {employer.mobNumber} {employer.companyName && employer.companyName !== ''? ` - ${employer.companyName}`: ``}
            </td>
            <td>
                Rs {employer.currentLeadValue}
            </td>
            <td>
                Rs {employer.previousLeadValue}
            </td>
        </tr>
    )
}