import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Ajax } from '../api';


class Dashboard extends Component {
	_isMounted = false;

    constructor(props) {
        super(props)
        this.state = {
            data: {}
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.getData();
    }
    componentWillUnmount = () => this._isMounted = false;
    setState = (state, callback) => this._isMounted && super.setState(state, callback);

    getData() {
        Ajax.get('information/admin/getdashboard')
            .then(data => {
                if(data.status && data.status === 'OK') {
                    this.setState({ data: data.content })
                    return;
                }
                this.setState({ loading: false });
            }).catch(() => this.setState({ loading: false }));
    }


	render() {
		let { data } = this.state;
		return (
			<div className="main">
				<div className="main-inner tf_section_wrapper tf_widgets_EmployeeAttendance pt-5">
					<div className="container">
						<div className="row">
							<div className="col-xl-6">
								<Link to="/admin/employers" className="tf_card d-flex justify-content-between align-items-center mb-4" style={{cursor: 'pointer', textDecoration: 'none'}}>
									<div className="tf_card_head">
										<h2 className="tf_count"><span id="projectCount">{data.employerCount}</span></h2>
										<h3 className="tf_title">Employer</h3>
									</div>
									<div className="tf_card_ico bg-primary">
										<i className="zmdi zmdi-library"></i>
									</div>
								</Link>
							</div>
							<div className="col-xl-6">
								<Link to="/admin/employees"  className="tf_card d-flex justify-content-between align-items-center mb-4" style={{cursor: 'pointer', textDecoration: 'none'}}>
									<div className="tf_card_head">
										<h2 className="tf_count"><span>{data.employeeCount}</span></h2>
										<h3 className="tf_title">Employee</h3>
									</div>
									<div className="tf_card_ico bg-warning">
										<i className="zmdi zmdi-layers"></i>
									</div>
								</Link>
							</div>
							<div className="col-xl-6">
								<Link to="/admin/products"  className="tf_card d-flex justify-content-between align-items-center mb-4" style={{cursor: 'pointer', textDecoration: 'none'}}>
									<div className="tf_card_head">
										<h2 className="tf_count"><span>{data.productCount}</span></h2>
										<h3 className="tf_title">Product</h3>
									</div>
									<div className="tf_card_ico bg-warning">
										<i className="zmdi zmdi-layers"></i>
									</div>
								</Link>
							</div>
						</div>
					
					</div>
				</div>
			</div>
		)
	}
}

export default Dashboard;