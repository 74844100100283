import React, { Component } from 'react';
import { removeUser } from '../Auth/user';
import { Link } from 'react-router-dom';

class LeadDropdown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            unique: Date.now(),
            selectedValue: ''
        }
        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = event => {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ isOpen: false });
        }
    }

    logout = () => {
        removeUser();
        window.location.reload()
    }

    render() {
        let { isOpen, unique } = this.state;
        return (
            <div className={`${unique}`} ref={this.wrapperRef}>
                <div className="nav-link dropdown-toggle cp" onClick={() => this.setState({ isOpen: !isOpen })}>
                    <i className="zmdi zmdi-accounts-alt"></i> Lead Management
                </div>
                <div className={`dropdown-menu ${isOpen? 'show': ''}`} aria-labelledby="navbarDropdown">
                    <Link className="dropdown-item" to="/admin/leads-employer">Leads Employer</Link>
                    <Link className="dropdown-item" to="/admin/leads-employee">Leads Employee</Link>
                    <Link className="dropdown-item" to="/admin/payment-history">Payment History</Link>
                </div>
            </div>
        )
    }
}
export default LeadDropdown;