import React, { Component } from 'react';
import { Ajax } from '../../api';
import ListItem from './ListItem';
import { Paginate, Row, Input } from '../../Theme';
import Meta from '../../Layout/Meta';
import ViewModal from './ViewModal';
import { confirmAlert } from 'react-confirm-alert';
import '../../styles/confirm-alert.css';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

class Products extends Component {
    _isMounted = false;
    products = []

    constructor(props) {
        super(props)
        this.state = {
            products: [],
            totalRecords: 0,
            loading: false,
            currentPage: 1,
            showViewModal: false,
            product: {},
            origCount: 0,
            searchText: ''
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.getProducts(1);
    }
    componentWillUnmount = () => this._isMounted = false;
    setState = (state, callback) => this._isMounted && super.setState(state, callback);

    getProducts(pageNumber) {
        let params = { pageNumber, recordCount };
        Ajax.get('product/admin/getallproducts', params)
            .then(data => {
                if(data.status && data.status === 'OK') {
                    this.products = data.content.productList
                    this.setState({ loading: false, totalRecords: data.content.count, origCount: data.content.count,
                        products: data.content.productList})
                    return;
                }
                this.setState({ loading: false });
            }).catch(() => this.setState({ loading: false }));
    }

    onPageChanged = data => {
        const { currentPage } = data;
        this.getProducts(currentPage);        
    }

    toggleViewModal = (index) => {
        let { products, product } = this.state;
        var show = false;
        if(index === '') {
            product = {};
            show = false;
        } else {
            product = products[index];
            show = true;
        }
        this.setState({ showViewModal: show, product });
    }

    confirmDelete = () => {
        confirmAlert({
            title: `Do you want to delete these products?`,
            message: '',
            buttons: [
                {
                    label: 'No',
                    className: 'btn btn-secondary'
                },
                {
                    label: 'Yes',
                    onClick: this.deleteProducts,
                    className: 'btn btn-primary'
                }
            ]
        });
    }

    deleteProducts = () => {
        let { selectedIds } = this.state;
        let params = { id: selectedIds.join(',')  }
        Ajax.call('product/admin/deleteproduct', params)
            .then(data => {
                if(data.status && data.status === 'OK') {
                    toast.success(data.message);
                } else {
                    toast.error(data.message);
                }
            }).catch(e => e)
    }

    removeProduct = index => {
        let { products } = this.state;
        products.splice(index, 1);
        this.setState({ products });
    }

    filterRows = (name, value) => {
        let products = this.products.filter(e => e.productName.toLowerCase().includes(value) || e.cost.toString().includes(value));
        this.setState({ products, searchText: value })
        if(value === '') {
            this.setState({ totalRecords: this.state.origCount });
        }
    }

    render() {
        let { products, loading, totalRecords, currentPage, product, searchText } = this.state;
        return (<>
            <Meta title="Products" />
            <div className="main">
                <div className="main-inner tf_section_wrapper tf_widgets_EmployeeAttendance">
                    <div className="container">
                        <Row>
                            <div className="col-sm-12">
                                <h3>Products</h3>
                            </div>
                            <div className="col-sm-6">
                                <Input value={searchText} onChange={this.filterRows} placeholder="Search" />
                            </div>
                            <div className="col-sm-12 text-right" style={{paddingBottom: '5px'}}>
                                <Link to="/admin/product/create" className="btn btn-primary">
                                    Add New Product
                                </Link>
                            </div>
                        </Row>
                        {loading? <h1>Loading...</h1>:<>
                            <Row>
                                <div className="col-sm-12">
                                    <table className="table table-hovered table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Cost</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {products.map((product, i) => (
                                                <ListItem key={i} index={i} product={product} 
                                                    toggleViewModal={this.toggleViewModal.bind(this)}
                                                    removeProduct={this.removeProduct.bind(this)} />
                                            ))}
                                             {
                                                products.length === 0 && (
                                                     <tr>
                                                        <th colSpan={3} style={{textAlign:'center'}}>
                                                            No data found!
                                                        </th>
                                                     </tr>
                                                )
                                             }
                                        </tbody>
                                    </table>
                                </div>
                            </Row>
                            {totalRecords > recordCount &&
                                <Row>
                                    <div className="col-sm-12">
                                        <div className="text-right" style={{float: 'right'}}>
                                            <Paginate
                                                totalRecords={totalRecords}
                                                pageLimit={recordCount}
                                                pageNeighbours={2}
                                                currentPage={currentPage}
                                                showStats={true}
                                                onPageChanged={this.onPageChanged} />
                                        </div>
                                    </div>
                                </Row>}
                        </>}
                    </div>
                </div>
            </div>
            <ViewModal showViewModal={this.state.showViewModal} product={product}
                toggleViewModal={this.toggleViewModal.bind(this)} />
        </>)
    }
}
var recordCount = 10;
export default Products;