import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getUser, removeUser } from '../Auth/user';
import TopMenu from './TopMenu';
import logo from "../assets/images/logo.png";

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            unique: Date.now(),
            selectedValue: ''
        }
        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = event => {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            if(this.state.isOpen) {
                this.setState({ isOpen: false });
            }
        }
    }

    logout = () => {
        removeUser();
        window.location.reload()
    }

    render() {
        let { isOpen, unique } = this.state;
        let user = getUser();
        return (<>
            <nav className={`navbar navbar-expand-lg navbar-light bg-white navbar-fixed-top ${unique}`} ref={this.wrapperRef}>
                <a className="navbar-brand" href="/admin">
                    {/* <h3>Job ka Shop</h3> */}
                    <img src={logo} style={{width: '100px'}} alt=""/>
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item dropdown">
                            <div className="nav-link dropdown-toggle cp" onClick={() => this.setState({ isOpen: !isOpen })}>
                                {user.username} <b className="caret"></b>
                            </div>
                            <div className={`dropdown-menu dropdown-menu-right ${isOpen? 'show': ''}`} aria-labelledby="navbarDropdown">
                                <Link to="/admin/change-password" className="cp dropdown-item">Change Password</Link>
                                <div onClick={this.logout} className="cp dropdown-item">Logout</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
            <TopMenu />
        </>)
    }
}
export default Header;