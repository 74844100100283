import React, { Component } from 'react';
import { LoadingButton, Number, Form, FormRow } from '../../Theme';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { Ajax } from '../../api/';
import { toast } from 'react-toastify';

class AssignLeads extends Component {
    _isMounted = false;

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            errors: {},
            employeeLeadValue: '',
            employerLeadValue: '0'
        }
        this.myRef = React.createRef()
    }

    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount = () => this._isMounted = false;
    setState = (state, callback) => this._isMounted && super.setState(state, callback);

    closeModal = () => {
        this.setState({ message: ''}, () => {
            this.props.toggleLeadModal('');
        });
    }

    assignLeads = e => {
        e.preventDefault();
        let { employeeLeadValue, employerLeadValue } = this.state;
        let { selectedIds } = this.props;
        if(employeeLeadValue === '' || employerLeadValue === '') {
            this.setState({ errors: {employeeLeadValue: true, employerLeadValue: true}});
            return false;
        }
        this.setState({ errors: {}, loading: true });
        let params = { employeeLeadValue, employerLeadValue, id: selectedIds.join(',') }
        Ajax.call('employee/admin/setemployeeleadvalue', params)
            .then(data => {
                if(data.status && data.status === "OK") {
                    toast.success(data.message);
                    this.props.toggleLeadModal('');
                } else if(data.message) {
                    toast.error(data.message);
                }
                this.setState({ loading: false });
            }).catch(() => {
                this.setState({ loading: false });
                toast.error("Something went wrong, please try again later.");
            })
    }

    render() {
        let { loading, errors, employeeLeadValue, employerLeadValue } = this.state;
        let { showLeadModal } = this.props;
        
        return (<>
            <div ref={this.myRef} />
            <Modal modalId="noti-modal"
              open={showLeadModal}
              onClose={this.closeModal}
              center
              container={this.myRef.current}>
              <div className="">
                    <div className="modal-header" style={{paddingLeft: '0'}}>
                        Set Lead Value
                    </div>
                    <div className="modal-body">
                        <Form errors={errors}>
                            <FormRow>
                                <div className="col-md-12" style={{width: '100%'}}>
                                    <Number label="Employee Lead Value" name="employeeLeadValue" value={employeeLeadValue}
                                        onChange={(name, value) => this.setState({ employeeLeadValue: value })} />
                                </div>
                            </FormRow>
                            <FormRow>
                                <div className="col-md-12" style={{width: '100%'}} hidden>
                                    <Number label="Employer Lead Value" name="employerLeadValue" value={employerLeadValue}
                                        onChange={(name, value) => this.setState({ employerLeadValue: value })} />
                                </div>
                            </FormRow>
                        </Form>
                    </div>
                    <div className="modal-footer">
                        {!loading? <button type="button" className="btn btn-signup" onClick={this.assignLeads}>
                            Submit
                        </button>:
                        <LoadingButton className="btn btn-signup" />}
                    </div>
                </div>
            </Modal>
        </>)
    }
}
export default AssignLeads;