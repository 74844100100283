import React from 'react';
import useForm from './useForm';
import { Form, FormRow, Select, Input, LoadingButton, Row, Submit, Textarea } from '../../Theme'
import Meta from '../../Layout/Meta'
import { Link, Redirect } from 'react-router-dom';

function Index(props) {

    var { fields, errors, postLoading, redirectTo, currencies, setFieldState, imageUpload, handleSubmit } = useForm(props);
    
    if(redirectTo) {return <Redirect to={redirectTo} />}

    return (<>
        <Meta title="Change Password" />
        <div className="main">
                <div className="main-inner tf_section_wrapper tf_widgets_EmployeeAttendance">
                    <div className="container">
                        <Row>
                            <div className="col-sm-12">
                                <h3>Change Password</h3>
                            </div>
                        </Row>
                        <Row>
                            <div className="col-sm-12">
                                <Form errors={errors} onSubmit={handleSubmit}>
                                    <FormRow>
                                        <div className="col-sm-7">
                                            <Input type="password" label="New Password" name="newpass" value={fields.newpass} onChange={setFieldState} />
                                        </div>
                                    </FormRow>
                                    <FormRow>
                                        <div className="col-sm-7">
                                            <Input type="password" label="Confirm Password" name="confirmpass" value={fields.confirmpass} onChange={setFieldState} />
                                        </div>
                                    </FormRow>
                                    <FormRow>
                                        <div className="col-sm-7">
                                            {postLoading? <LoadingButton className="btn btn-primary mr-10" />: 
                                                <Submit label="Submit" className="btn btn-primary mr-10" />}
                                            <Link to="/admin" className="btn btn-warning">Back</Link>
                                        </div>
                                    </FormRow>
                                </Form>
                            </div>
                        </Row>
                    </div>
                </div>
            </div>
    </>)
}
export default Index;