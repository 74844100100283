import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import web from '../config/web'
import Home from '../Home';
import Login from '../Auth/Login';
import Admin from '../Admin';

function Routes() {
    return (
        <Router basename={web.baseFolder}>
            <HelmetProvider>
                <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/admin" component={Admin} />
                    <Route path="/" component={Home} />
                </Switch>
                
            </HelmetProvider>
        </Router>
    )
}
export default Routes;