import React from 'react';
import './styles/bootstrap.min.css';
import './styles/style.css';
import Routes from './routes/Routes.js';
import { Toastify } from './Theme';
import { checkSessionExpire } from './Auth/user';

function App() {
    checkSessionExpire();
    return (
        <div className="job-fair-app">
            <Routes />
            <Toastify />
        </div>
    );
}

export default App;