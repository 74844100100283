import React from 'react';
import useForm from './useForm';
import { Form, FormRow, Select, Input, LoadingButton, Row, Submit, Textarea } from '../../Theme'
import Meta from '../../Layout/Meta'
import { Link, Redirect } from 'react-router-dom';

function ProductForm(props) {

    var { fields, errors, postLoading, redirectTo, currencies, setFieldState, imageUpload, handleSubmit } = useForm(props);
    
    if(redirectTo) {return <Redirect to={redirectTo} />}

    return (<>
        <Meta title="Save Product" />
        <div className="main">
                <div className="main-inner tf_section_wrapper tf_widgets_EmployeeAttendance">
                    <div className="container">
                        <Row>
                            <div className="col-sm-12">
                                <h3>Save Product</h3>
                            </div>
                        </Row>
                        <Row>
                            <div className="col-sm-12">
                                <Form errors={errors} onSubmit={handleSubmit}>
                                    <FormRow>
                                        <div className="col-sm-7">
                                            <Input label="Name" name="productName" value={fields.productName} onChange={setFieldState} />
                                        </div>
                                    </FormRow>
                                    <FormRow>
                                        <div className="col-sm-7">
                                            <Input label="Cost" name="cost" value={fields.cost} onChange={setFieldState} />
                                        </div>
                                    </FormRow>
                                    <FormRow>
                                        <div className="col-sm-7">
                                            <Select label="Currency" name="currency" value={fields.currency}
                                             onChange={setFieldState} options={currencies} />
                                        </div>
                                    </FormRow>
                                    <FormRow>
                                        <div className="col-sm-7">
                                            <Textarea label="Description" name="productDescription" value={fields.productDescription} onChange={setFieldState} maxlength = "255"/>
                                        </div>
                                    </FormRow>
                                    <FormRow>
                                        <div className="col-sm-7">
                                            <input type="file" className={`form-control ${errors.file? 'has-error': ''}`} name="file" onChange={imageUpload} />
                                            {errors.file && <div className="help-block error">{errors.file}</div>}
                                        </div>
                                    </FormRow>
                                    <FormRow>
                                        <div className="col-sm-7">
                                            {postLoading? <LoadingButton className="btn btn-primary mr-10" />: 
                                                <Submit label="Save" className="btn btn-primary mr-10" />}
                                            <Link to="/admin/products" className="btn btn-warning">Back</Link>
                                        </div>
                                    </FormRow>
                                </Form>
                            </div>
                        </Row>
                    </div>
                </div>
            </div>
    </>)
}
export default ProductForm;