import React, { Component } from 'react';
import { Ajax } from '../../api';
import ListItem from './ListItem';
import { Paginate, Row, Input, Checkbox } from '../../Theme';
import AssignLeads from './AssignLeads';
import AssignFreeLeads from './AssignFreeLeads';
import Meta from '../../Layout/Meta'

class Employees extends Component {
    _isMounted = false;
    employees = []

    constructor(props) {
        super(props)
        this.state = {
            employees: [],
            totalRecords: 0,
            loading: false,
            currentPage: 1,
            showLeadModal: false,
            showFreeModal: false,
            searchText: '',
            selectedIds: [],
            origCount: 0
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.getEmployees(1);
    }
    componentWillUnmount = () => this._isMounted = false;
    setState = (state, callback) => this._isMounted && super.setState(state, callback);

    getEmployees(pageNumber) {
        let params = { pageNumber, recordCount };
        Ajax.get('employee/admin/getemployees', params)
            .then(data => {
                if(data.status && data.status === 'OK') {
                    this.employees = data.content.employeeList
                    this.setState({ loading: false, totalRecords: data.content.count, origCount: data.content.count,
                        employees: data.content.employeeList})
                    return;
                }
                this.setState({ loading: false });
            }).catch(() => this.setState({ loading: false }));
    }

    onPageChanged = data => {
        const { currentPage } = data;
        this.getEmployees(currentPage);        
    }

    updateStatus = index => {
        let { employees } = this.state;
        employees[index].companyStatus = !employees[index].companyStatus;
        this.setState({ employees });
    }

    toggleLeadModal = (index) => {
        var show = false;
        if(index === '') {
            show = false;
        } else {
            show = true;
        }
        this.setState({ showLeadModal: show, selectedIds: [] });
    }

    toggleFreeModal = (index) => {
        var show = false;
        if(index === '') {
            show = false;
        } else {
            show = true;
        }
        this.setState({ showFreeModal: show, selectedIds: [] });
    }

    filterRows = (name, value) => {
        let employees = this.employees.filter(e => e.mobNumber.includes(value) || e.firstName.toLowerCase().includes(value));
        this.setState({ employees, searchText: value, totalRecords: employees.length })
        if(value === '') {
            this.setState({ totalRecords: this.state.origCount });
        }
    }

    selectCheckbox = (id, type) => {
        let { selectedIds, employees } = this.state;
        if(type === 'SELECT_ALL' && id < 0) {
            if(employees.length === selectedIds.length) {
                selectedIds = [];
            } else {
                selectedIds = employees.map(pro => pro.id);
            }
        } else if(type === 'SINGLE') {
            let index = selectedIds.indexOf(id);
            if(index >= 0) {
                selectedIds.splice(index, 1);
            } else {
                selectedIds.push(id);
            }
        }
        this.setState({ selectedIds })
    }

    render() {
        let { employees, loading, totalRecords, currentPage, showFreeModal, showLeadModal,
            searchText, selectedIds } = this.state;
        return (<>
            <Meta title="Assign Leads" />
            <div className="main">
                <div className="main-inner tf_section_wrapper tf_widgets_EmployeeAttendance">
                    <div className="container">
                        <Row>
                            <div className="col-sm-12">
                                <h3>Employees</h3>
                            </div>
                            <div className="col-sm-6">
                                <Input value={searchText} onChange={this.filterRows} placeholder="Search" />
                            </div>
                            <div className="col-sm-6 text-right" style={{paddingBottom: '5px'}}>
                                <button className="btn btn-primary mr-10" onClick={() => this.setState({ showLeadModal: true })}
                                    disabled={selectedIds.length === 0}>
                                    Set Lead Value
                                </button>
                                <button className="btn btn-primary" onClick={() => this.setState({ showFreeModal: true })}
                                    disabled={selectedIds.length === 0}>
                                    Assign Free Leads
                                </button>
                            </div>
                        </Row>
                        {loading? <h1>Loading...</h1>:<>
                            <Row>
                                <div className="col-sm-12" style={{paddingTop: '10px'}}>
                                    <table className="table table-hovered table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th className="text-center">
                                                    <Checkbox name="selectAll" onChange={() => this.selectCheckbox(-1, 'SELECT_ALL')}
                                                        checked={selectedIds.length === employees.length} />
                                                </th>
                                                <th>Username</th>
                                                <th>Name</th>
                                                <th>Current Lead Value</th>
                                                <th>Previous Lead Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {employees.map((emp, i) => (
                                             !emp.deleteStatus && <ListItem key={i} index={i} employer={emp} selectedIds={selectedIds}
                                                    selectCheckbox={this.selectCheckbox.bind(this)} />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Row>
                            {totalRecords > recordCount &&
                                <Row>
                                    <div className="col-sm-12">
                                        <div className="text-right" style={{float: 'right'}}>
                                            <Paginate
                                                totalRecords={totalRecords}
                                                pageLimit={recordCount}
                                                pageNeighbours={2}
                                                currentPage={currentPage}
                                                showStats={true}
                                                onPageChanged={this.onPageChanged} />
                                        </div>
                                    </div>
                                </Row>}
                        </>}
                    </div>
                </div>
            </div>
            <AssignFreeLeads showFreeModal={showFreeModal} selectedIds={selectedIds}
                toggleFreeModal={this.toggleFreeModal.bind(this)} />
            <AssignLeads showLeadModal={showLeadModal} selectedIds={selectedIds}
                toggleLeadModal={this.toggleLeadModal.bind(this)} />
        </>)
    }
}
var recordCount = 10;
export default Employees;