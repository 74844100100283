import React from 'react';
import { Redirect } from 'react-router-dom';
import { getUser } from '../Auth/user';
import { isEmpty } from '../helpers';

function Home() {
    let user = getUser();
    if(isEmpty(user)) {
        return <Redirect to="/login" />
    } else {
        return <Redirect to="/admin" />
    }
}
export default Home;