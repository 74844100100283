const initialState = {
    locale: 'en'
};
  
const intlData = (state = initialState, action) => {
    if (action === undefined) return state;
    switch (action.type) {
        case 'UPDATE_LANGUAGE':
            return {
                locale: action.language
            };
        default:
            return state;
    }
};
export default intlData;