import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import '../../styles/confirm-alert.css';
import { Ajax } from '../../api';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function ListItem(props) {

    let { product, index } = props;

    function confirmDelete() {
        confirmAlert({
            title: `Do you want to delete this product?`,
            message: '',
            buttons: [
                {
                    label: 'No',
                    className: 'btn btn-secondary'
                },
                {
                    label: 'Yes',
                    onClick: deleteProduct,
                    className: 'btn btn-primary'
                }
            ]
        });
    }

    function deleteProduct() {
        let url = `product/admin/deleteproduct?productId=${product.id}`;
        Ajax.call(url, {})
            .then(data => {
                if(data.status && data.status === 'OK') {
                    toast.success(data.message);
                    props.removeProduct(index);
                } else {
                    toast.error(data.message);
                }
            }).catch(e => toast.error('Something went wrong, please try again later.'))
    }

    function showViewModal() {
        props.toggleViewModal(index);
    }

    return (
        <tr>
            <td>{product.productName}</td>
            <td>{product.cost}</td>
            <td>
                <Link to={`/admin/product/edit/${product.id}`} className="btn btn-primary mr-10">
                    <i className="fa fa-edit" aria-hidden="true"></i>
                </Link>
                <button title="View" className="btn btn-warning mr-10" onClick={showViewModal}>
                    <i className="fa fa-eye" aria-hidden="true"></i>
                </button>
                <button className="btn btn-danger" onClick={confirmDelete}>
                    <i className="fa fa-trash" aria-hidden="true"></i>
                </button>
            </td>
        </tr>
    )
}