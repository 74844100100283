import React, { Component } from 'react';
import { Ajax } from '../../api';
import ListItem from './ListItem';
import { Paginate, Row } from '../../Theme';
import Meta from '../../Layout/Meta'
import ExcelExport from './ExcelExport'
import { isEmpty } from '../../helpers'
import DatePicker from 'react-date-picker';

class Payments extends Component {
    _isMounted = false;
    payments = []

    constructor(props) {
        super(props)
        this.state = {
            payments: [],
            totalRecords: 0,
            loading: false,
            currentPage: 1,
            employer: {},
            origCount: 0,
            exportData: []
        }
    }

    componentDidMount() {
        this._isMounted = true;
        // this.getPayments(1, '', '', false);
    }
    componentWillUnmount = () => this._isMounted = false;
    setState = (state, callback) => this._isMounted && super.setState(state, callback);

    getPayments(pageNumber, startDate, endDate, getExport) {
        let params = { pageNumber, recordCount, startDate, endDate };
        Ajax.get('leads/admin/getpaymenthistoryforcompanies', params)
            .then(data => {
                if(data.status && data.status === 'OK' &&!isEmpty(data.content)) {
                    this.payments = data.content.companyList
                    this.setState({ loading: false, totalRecords: data.content.count, origCount: data.content.count,
                        payments: data.content.companyList})
                    if(getExport) {
                        this.getExportableData(data.content.count)
                    }
                    return;
                } else {
                    this.setState({ loading: false, totalRecords: 0, origCount: 0,
                        payments: []})
                }
                this.setState({ loading: false });
            }).catch(() => this.setState({ loading: false }));
    }

    getExportableData(count) {
        let { startDate, endDate } = this.getFormattedDate()
        let params = { pageNumber: 1, recordCount: count, startDate, endDate };
        Ajax.get('leads/admin/getpaymenthistoryforcompanies', params)
            .then(data => {
                if(data.status && data.status === 'OK') {
                    this.setState({ exportData: data.content.companyList })
                    return;
                }
            }).catch(e => e);
    }

    onPageChanged = data => {
        const { currentPage } = data;
        let { startDate, endDate } = this.getFormattedDate()
        this.getPayments(currentPage, startDate, endDate, false);        
    }

    startDateChange = (value) => {
        this.setState({ startDate: value })
    }

    endDateChange = (value) => {
        this.setState({ endDate: value })
    }

    filterRecords = () => {
        let { startDate, endDate } = this.getFormattedDate()
        this.getPayments(1, startDate, endDate, true);
    }

    getFormattedDate() {
        let { startDate, endDate } = this.state
        if(!startDate ) {
            return {startDate: '', endDate: ''}
        }
        let sd = new Date(startDate)
        let sdf = `${sd.getFullYear()}-${sd.getMonth() + 1}-${sd.getDate()}`
        let ed = new Date(endDate)
        let edf = `${ed.getFullYear()}-${ed.getMonth() + 1}-${ed.getDate()}`
        return { startDate: sdf, endDate: edf }
    }

    clearRecords = () => {
        this.payments = []
        this.setState({ payments: [], totalRecords: 0, origCount: 0})
    }

    render() {
        let { payments, loading, totalRecords, currentPage, 
            startDate, endDate, exportData } = this.state;
        return (<>
            <Meta title="Payment History" />
            <div className="main">
                <div className="main-inner tf_section_wrapper tf_widgets_EmployeeAttendance">
                    <div className="container">
                        <Row>
                            <div className="col-sm-12">
                                <h3>Payments</h3>
                            </div>
                            <div className="col-sm-3" style={{paddingBottom: '5px'}}>
                                <DatePicker className="date-input"
                                    onChange={this.startDateChange} showLeadingZeros={true}
                                    value={startDate} closeCalendar={true} />
                            </div>
                            <div className="col-sm-3" style={{paddingBottom: '5px'}}>
                                <DatePicker className="date-input"
                                    onChange={this.endDateChange}
                                    value={endDate} showLeadingZeros={true}
                                    minDate={startDate || new Date()} />
                            </div>
                            {(startDate && endDate) && <div className="col-sm-3" style={{paddingBottom: '5px'}}>
                                <button className="btn btn-primary mr-10" onClick={this.filterRecords}>
                                    Filter
                                </button>
                                {!isEmpty(exportData) && <ExcelExport exportData={exportData} startDate={startDate} endDate={endDate} />}
                                <button className="btn btn-primary" onClick={this.clearRecords}>
                                    Clear
                                </button>
                            </div>}
                        </Row>
                        {loading? <h1>Loading...</h1>:<>
                            <Row>
                                <div className="col-sm-12">
                                    <table className="table table-hovered table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Company</th>
                                                <th>Amount Paid</th>
                                                <th>Purchased Leads</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {payments.map((payment, i) => (
                                                <ListItem key={i} index={i} payment={payment} />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Row>
                            {totalRecords > recordCount &&
                                <Row>
                                    <div className="col-sm-12">
                                        <div className="text-right" style={{float: 'right'}}>
                                            <Paginate
                                                totalRecords={totalRecords}
                                                pageLimit={recordCount}
                                                pageNeighbours={2}
                                                currentPage={currentPage}
                                                showStats={true}
                                                onPageChanged={this.onPageChanged} />
                                        </div>
                                    </div>
                                </Row>}
                        </>}
                    </div>
                </div>
            </div>
        </>)
    }
}
var recordCount = 10;
export default Payments;