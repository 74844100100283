import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import { getUser } from '../Auth/user';
import { isEmpty } from '../helpers';
import { Redirect } from 'react-router-dom';
import UserRoute from '../routes/UserRoute';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import Dashboard from './Dashboard';
import Employers from './Employers/index';
import Employees from './Employees/index';
import Products from './Product/index'
import SaveProduct from './Product/ProductForm'
import LeadsEmployer from './Lead/index'
import PaymentHistory from './PaymentHistory/index'
import LeadsEmployee from './LeadEmployee/index'
import ChangePassword from './ChangePassword/index'

class Admin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirectTo: ''
        };
    }

    componentDidMount() {
        let user = getUser();
        if(isEmpty(user)) {
            this.setState({ redirectTo: '/login' });
        }
    }

    render() {
        let { redirectTo } = this.state;
        if(redirectTo) {return <Redirect to={redirectTo} />}
        let user = getUser();
        return (<>
            {!isEmpty(user) && <>
            <Header />
                <Switch>
                    <UserRoute path="/admin/employers" component={Employers} />
                    <UserRoute path="/admin/employees" component={Employees} />
                    <UserRoute path="/admin/products" component={Products} />
                    <UserRoute path="/admin/product/create" component={SaveProduct} />
                    <UserRoute path="/admin/product/edit/:id" component={SaveProduct} />
                    <UserRoute path="/admin/leads-employer" component={LeadsEmployer} />
                    <UserRoute path="/admin/leads-employee" component={LeadsEmployee} />
                    <UserRoute path="/admin/payment-history" component={PaymentHistory} />
                    <UserRoute path="/admin/change-password" component={ChangePassword} />
                    <UserRoute path="/admin" component={Dashboard} />
                </Switch>
            <Footer />
            </>}
        </>)
    }
}
export default Admin;