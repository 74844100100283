import React from 'react';
import { Route } from 'react-router-dom';
import Authenticate from '../Auth/Authenticate';


const UserRoute = ({ component: Component, search, ...rest }) => {
    return (
        <Route
            {...rest}
            render={matchProps => (
                <Authenticate>
                    <Component {...matchProps} />
                </Authenticate>
            )}
        />
    );
};

export default UserRoute;