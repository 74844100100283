import React, { Component } from 'react';
import authUserAction from "../services/User/actions";
import { isEmpty } from '../helpers';
import { connect } from 'react-redux';
import { getUser } from '../Auth/user';
import { Redirect } from 'react-router-dom';
import { Animate, Body, Content, Card, Container, Row, FormRow } from '../Theme';

class Authenticate extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            redirectTo: '',
            loading: false
        };
    }

    componentDidMount() {
        this._isMounted = true;
        let user = getUser();
        if(isEmpty(user)) {
            this.setState({ redirectTo: '/login' });
            return false;
        }
        this.getAdmin();
    }

    getAdmin() {
        this.props.dispatch(authUserAction({username: 'admin'}));
    }

    /** unmounts the component to prevent the async state */
    componentWillUnmount = () => this._isMounted = false;

    /**sets the component state */
    setState = (state, callback) => this._isMounted && super.setState(state, callback);

    render() {
        let { redirectTo, loading } = this.state;
        let { authUser } = this.props;
        if(redirectTo) {return <Redirect to={redirectTo} />}
        return (<>
            {!isEmpty(authUser)? this.props.children:
                <Body>
                    <Content>
                        <Container>
                            <Row>
                                <div className="col-xs-12 col-sm-8 col-md-5 text-center col-sm-offset-2 col-md-offset-4">
                                    <Animate>
                                        <Card>
                                            <FormRow>
                                                {loading? <h1>Loading...</h1>:
                                                    <h1>No User found.</h1>}
                                            </FormRow>
                                        </Card>
                                    </Animate>
                                </div>
                            </Row>
                        </Container>
                    </Content>
                </Body>}
        </>)
    }
}

const mapStateToProps = state => ({authUser: state.user.user})
export default connect(mapStateToProps)(Authenticate);