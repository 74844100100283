import React from 'react';

export default function ListItem(props) {

    let { payment } = props;

    return (
        <tr>
            <td>
                {payment.company.mobNumber} {payment.company.companyName && payment.company.companyName !== ''? ` - ${payment.company.companyName}`: ``}
            </td>
            <td>{payment.amountPaid}</td>
            <td>{payment.purchasedLeads}</td>
            <td>{payment.date}</td>
        </tr>
    )
}