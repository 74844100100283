import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import '../../styles/confirm-alert.css';
import { Ajax } from '../../api';

export default function ListItem(props) {

    let { employer, index } = props;

    function confirmStatus() {
        let m = employer.companyStatus? 'Disable': 'Enable';
        confirmAlert({
            title: `Do you want to ${m} this company?`,
            message: '',
            buttons: [
                {
                    label: 'No',
                    className: 'btn btn-secondary'
                },
                {
                    label: 'Yes',
                    onClick: changeStatus,
                    className: 'btn btn-primary'
                }
            ]
        });
    }

    function changeStatus() {
        let status = employer.companyStatus? 'False': 'True';
        let params = { id: employer.id, status }
        Ajax.call('employer/admin/manageemployer', params)
            .then(data => {
                props.updateStatus(index);
            }).catch(e => e)
    }

    function showNotiModal() {
        props.toggleNotiModal(index);
    }

    function showViewModal() {
        props.toggleViewModal(index);
    }

    return (
        <tr className={employer.companyStatus? '': 'disabled-row'}>
            <td>
                <span style={{cursor: 'pointer'}} onClick={showViewModal}>
                    {employer.mobNumber} {employer.companyName && employer.companyName !== ''? ` - ${employer.companyName}`: ``}
                </span>
            </td>
            <td>{employer.employer.emailId}</td>
            <td>{employer.employer.city}</td>
            <td>{employer.employer.state}</td>
            <td>{employer.employer.createdDatetime}</td>
            <td>
                {employer.companyStatus? <button className="btn btn-primary mr-10" title="Enable" onClick={confirmStatus}>
                    <i className="fa fa-check-square-o" aria-hidden="true"></i>
                </button>: <button className="btn btn-danger mr-10" title="Disable" onClick={confirmStatus}>
                    <i className="fa fa-ban" aria-hidden="true"></i>
                </button>}
                {employer.companyStatus && <button title="Send Notification" className="btn btn-warning" onClick={showNotiModal}>
                    <i className="fa fa-bell" aria-hidden="true"></i>
                </button>}
            </td>
        </tr>
    )
}