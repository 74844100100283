import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import '../styles/paginate.css'

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

const range = (from, to, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
        range.push(i);
        i += step;
    }

    return range;
};

class Paginate extends Component {
    constructor(props) {
        super(props);
        const {
            totalRecords = null,
            pageLimit = 30,
            pageNeighbours = 0,
            currentPage = 1
        } = props;

        this.pageLimit = typeof pageLimit === "number" ? pageLimit : 30;
        this.totalRecords = typeof totalRecords === "number" ? totalRecords : 0;

        this.pageNeighbours =
            typeof pageNeighbours === "number"
                ? Math.max(0, Math.min(pageNeighbours, 2))
                : 0;
        this.totalPages = Math.ceil(this.totalRecords / this.pageLimit);
        this.state = { currentPage: currentPage };
    }
    /**
     * jumps to the page
     */
    gotoPage = page => {
        const { onPageChanged = f => f } = this.props;
        const currentPage = Math.max(0, Math.min(page, this.totalPages));
        
        const paginationData = {
            currentPage,
            totalPages: this.totalPages,
            pageLimit: this.pageLimit,
            totalRecords: this.totalRecords
        };
        this.setState({ currentPage }, () => onPageChanged(paginationData));
    };
    /**
     * handles the page click event
     */
    handleClick = (page, evt) => {
        evt.preventDefault();
        
        if (this.state.currentPage === page) {
            return;
        }
        this.gotoPage(page);
    };
    /**
     * handles the left button click
     */
    handleMoveLeft = evt => {
        evt.preventDefault();
        this.gotoPage(this.state.currentPage - this.pageNeighbours * 2 - 1);
    };
    /**
     * handles the right button click
     */
    handleMoveRight = evt => {
        evt.preventDefault();
        this.gotoPage(this.state.currentPage + this.pageNeighbours * 2 + 1);
    };
    /**
     * fetches the page number
     */
    fetchPageNumbers = () => {
        const totalPages = this.totalPages;
        const currentPage = this.state.currentPage;
        const pageNeighbours = this.pageNeighbours;

        const totalNumbers = this.pageNeighbours * 2 + 3;
        const totalBlocks = totalNumbers + 2;

        if (totalPages > totalBlocks) {
            let pages = [];

            const leftBound = currentPage - pageNeighbours;
            const rightBound = currentPage + pageNeighbours;
            const beforeLastPage = totalPages - 1;

            const startPage = leftBound > 2 ? leftBound : 2;
            const endPage =
                rightBound < beforeLastPage ? rightBound : beforeLastPage;

            pages = range(startPage, endPage);

            const pagesCount = pages.length;
            const singleSpillOffset = totalNumbers - pagesCount - 1;

            const leftSpill = startPage > 2;
            const rightSpill = endPage < beforeLastPage;

            const leftSpillPage = LEFT_PAGE;
            const rightSpillPage = RIGHT_PAGE;

            if (leftSpill && !rightSpill) {
                const extraPages = range(
                    startPage - singleSpillOffset,
                    startPage - 1
                );
                pages = [leftSpillPage, ...extraPages, ...pages];
            } else if (!leftSpill && rightSpill) {
                const extraPages = range(
                    endPage + 1,
                    endPage + singleSpillOffset
                );
                pages = [...pages, ...extraPages, rightSpillPage];
            } else if (leftSpill && rightSpill) {
                pages = [leftSpillPage, ...pages, rightSpillPage];
            }
            return [1, ...pages, totalPages];
        }
        return range(1, totalPages);
    };

    render() {
        if (!this.totalRecords) return null;

        if (this.totalPages === 1) return null;

        const { currentPage } = this.state;
        
        const pages = this.fetchPageNumbers();

        let showFrom = currentPage === 1? 1: ((currentPage - 1)  * this.pageLimit) + 1;
        let showTo = currentPage === 1? this.pageLimit: showFrom + this.pageLimit;
        if(currentPage === 1) {
            showTo = this.pageLimit;
        } else {
            showTo = (showFrom + this.pageLimit) - 1;
            if(showTo > this.totalRecords) {
                showTo = (this.totalRecords - showFrom) === 0? this.totalRecords: this.totalRecords - showFrom;
            }
        }

        return (
            <Fragment>
                <ul className="pagination">
                    {pages.map((page, index) => {
                        if (page === LEFT_PAGE)
                            return (
                                <li key={index} className="page-item">
                                    <span className="page-link" aria-label="Previous" onClick={this.handleMoveLeft}>
                                        <span aria-hidden="true">&laquo;</span>
                                        <span className="sr-only"> Previous</span>
                                    </span>
                                </li>
                            );

                        if (page === RIGHT_PAGE)
                            return (
                                <li key={index} className="page-item">
                                    <span className="page-link" href="#" aria-label="Next" onClick={this.handleMoveRight}>
                                        <span aria-hidden="true">&raquo;</span>
                                        <span className="sr-only">Next</span>
                                    </span>
                                </li>
                            );

                        return (
                            <li key={index} className={`page-item${currentPage === page ? " active" : ""}`}>
                                <span className="page-link" href="#" onClick={e => this.handleClick(page, e)}>
                                    {page}
                                </span>
                            </li>
                        );
                    })}
                </ul>
                {this.props.showStats && <div>
                    Showing {showFrom} to {showTo} of {this.totalRecords} entries
                </div>}
            </Fragment>
        );
    }
}

Paginate.propTypes = {
    totalRecords: PropTypes.number.isRequired,
    pageLimit: PropTypes.number,
    pageNeighbours: PropTypes.number,
    onPageChanged: PropTypes.func
};
export default Paginate;