import React, { useState, useEffect } from "react";
import ReactExport from "react-export-excel";
import { isEmpty } from "../../helpers";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Download = (props) => {

    var [exportData, setExportData] = useState([])


    useEffect(() => {
        if(!isEmpty(props.exportData)) {
            let exportData = props.exportData.map(b => {
                return {
                    mobNumber: b.company.mobNumber,
                    firstName: b.company.firstName,
                    emailId: b.company.emailId,
                    amountPaid: b.amountPaid,
                    purchasedLeads: b.purchasedLeads,
                    date: b.date
                }
            })
            setExportData(exportData)
        }
    }, [props.exportData])

    function getFormattedDate() {
        let { startDate, endDate } = props
        if(!startDate ) {
            return {startDate: '', endDate: ''}
        }
        let sd = new Date(startDate)
        let sdf = `${sd.getFullYear()}-${sd.getMonth() + 1}-${sd.getDate()}`
        let ed = new Date(endDate)
        let edf = `${ed.getFullYear()}-${ed.getMonth() + 1}-${ed.getDate()}`
        return { startDate: sdf, endDate: edf }
    }

    let { startDate, endDate } = getFormattedDate()

    return (<>
        <ExcelFile element={<button className="btn btn-primary mr-10">Export</button>} filename={`Payment_History_${startDate}_${endDate}`}>
            <ExcelSheet data={exportData} name="report">
                <ExcelColumn label="Mobile Number" value="mobNumber"/>
                <ExcelColumn label="Name" value="firstName"/>
                <ExcelColumn label="Email" value="emailId"/>
                <ExcelColumn label="Amount Paid" value="amountPaid"/>
                <ExcelColumn label="Purchased Leads" value="purchasedLeads"/>
                <ExcelColumn label="Date" value="date"/>
            </ExcelSheet>
        </ExcelFile>
    </>);
}
export default Download