import React from 'react';
import { FormRow } from '../../Theme';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

function ViewModal(props) {
    var myRef = React.useRef();
    let { showViewModal, product } = props;
    var documentName = ''
    if(product.documentURL) {
        var urlParams = new URL(product.documentURL).searchParams;
        documentName = urlParams.get('document');
    }

    function closeModal() {
        props.toggleViewModal('');
    }

    return (<>
        <div ref={myRef} />
        <Modal modalId="noti-modal"
            open={showViewModal}
            onClose={closeModal}
            center
            container={myRef.current}>
            <div className="">
                <div className="modal-body">
                    <div className="modal-header" style={{paddingLeft: '0'}}>
                        <h4 style={{marginBottom: "0"}}>View Product</h4>
                    </div>
                    <FormRow>
                        <div className="col-md-12">
                            Name: {product.productName}
                        </div>
                    </FormRow>
                    <FormRow>
                        <div className="col-md-12">
                            Description: {product.productDescription}
                        </div>
                    </FormRow>
                    <FormRow>
                        <div className="col-md-12">
                            Cost: {product.cost}
                        </div>
                    </FormRow>
                    <FormRow>
                        <div className="col-md-12">
                            Created Date: {product.createdDatetime}
                        </div>
                    </FormRow>
                    {product.documentURL !== '' && <FormRow>
                        <div className="col-md-12">
                            <span style={{marginRight: '5px'}}>Document: </span>
                            <a className="" href={`${product.documentURL}`} download>
                                {documentName? documentName: 'Download'}
                            </a>
                        </div>
                    </FormRow>}
                </div>
            </div>
        </Modal>
    </>)
}
export default ViewModal;