import { useState, useEffect } from 'react';
import { Ajax } from '../../api';
import validate from '../../lib/validate'
import { isEmpty } from '../../helpers';
import { toast } from 'react-toastify';

export default function useForm(props, uploadInput) {
    var [ state, setState ] = useState({
        fields: {id: 0, currency: 'INR'},
        errors: {},
        postLoading: false,
        isEdit: false,
        redirectTo: '',
        currencies: [{text: 'INR', value: 'INR'}]
    });

    useEffect(() => {
        if(props.match.params.id) {
            let params = {productId: props.match.params.id}
            Ajax.get('product/getproductbyid', params)
                .then(data => {
                    if(data.status && data.status === 'OK') {
                        setState(prev => ({...prev, fields: data.content, isEdit: true}));
                    }
                }).catch(e => e)
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = e => {
        e.preventDefault();
        let errors = validateForm();
        if(!isEmpty(errors)) {
            return false;
        }
        setState(prev => ({ ...prev, postLoading: true}))
        let { file } = state;
        if(isEmpty(file)) {
            saveProduct();
        } else {
            saveProductWithDoc();
        }
    }

    function saveProduct() {
        Ajax.call('product/admin/saveproduct', state.fields)
            .then(data => {
                if(data.status && data.status === 'OK') {
                    toast.success(data.message)
                    setState(prev => ({ ...prev, redirectTo: '/admin/products'}))
                    return
                } else {
                    toast.error(data.message)
                }
                setState(prev => ({ ...prev, postLoading: false}))
            }).catch(() => {
                toast.error('Something went wrong, please try again.')
                setState(prev => ({ ...prev, postLoading: false}))
            })
    }

    function saveProductWithDoc() {
        let { fields, file } = state;
        const formData = new FormData();
        let params = {
            id: fields.id,
            productName: fields.productName,
            categoryName: '',
            cost: fields.cost,
            productDescription: fields.productDescription
        }  
        for(let i in params) {
            formData.append(i, params[i]);
        }
        formData.append('files', file);
        Ajax.formData('product/admin/saveproductwithdocument', formData)
            .then(data => {
                if(data.status && data.status === 'OK') {
                    toast.success(data.message);
                    setState(prev => ({ ...prev, redirectTo: '/admin/products'}))
                    return
                } else if(data.message) {
                    toast.error(data.message);
                }
                setState(prev => ({ ...prev, postLoading: false}))
            })
            .catch(() => {
                toast.error('Something went wrong, please try again.')
                setState(prev => ({ ...prev, postLoading: false}))
            });
    }

    function validateForm() {
        let rules = {
            productName: {required: true},
            cost: {required: true, number: true}
        }
        let messages = {
            productName: {required: 'This field is required.'},
            cost: {required: 'This field is required.', number: 'Please enter valid number'}
        }
        if(!state.isEdit) {
            rules.file = {required: true}
            messages.file = {required: 'This field is required.'}
        }
        let _fields = {...state.fields};
        _fields.file = state.file;
        let errors = validate(_fields, rules, messages);
        setState(prev => ({ ...prev, errors }))
        return errors;
    }

    /** handles the input field change event */
    const setFieldState = (name, value) => {
        let { fields, errors } = state;
        let _fields = { ...fields, [name]: value};
        let _errors = {...errors};
        delete _errors[name];
        setState(prev => ({ ...prev, errors: _errors, fields: _fields }));
    }

    const imageUpload = e => {
        let file = e.target.files[0];
        setState(prev => ({ ...prev, file }));
    }

    return {...state, setFieldState, imageUpload, handleSubmit };
}