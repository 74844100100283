import React, { Component } from 'react';
import { LoadingButton, Textarea, Form, FormRow } from '../../Theme';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { Ajax } from '../../api/';
import { toast } from 'react-toastify';

class SendToAll extends Component {
    _isMounted = false;

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            errors: {},
            message: ''
        }
        this.myRef = React.createRef()
    }

    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount = () => this._isMounted = false;
    setState = (state, callback) => this._isMounted && super.setState(state, callback);

    closeModal = () => {
        this.setState({ message: ''}, () => {
            this.props.toggleNotiAllModal('');
        });
    }

    sendNotification = e => {
        e.preventDefault();
        let { message } = this.state;
        if(message === '') {
            this.setState({ errors: {message: true}});
            return false;
        }
        this.setState({ errors: {}, loading: true });
        let params = {
            sendToAll: true,
            notificationMessage: message
        }
        Ajax.call('notification/saveemployernotification', params)
            .then(data => {
                if(data.status && data.status === "OK") {
                    toast.success(data.message);
                    this.props.toggleNotiAllModal('');
                } else if(data.message) {
                    toast.error(data.message);
                }
                this.setState({ loading: false });
            }).catch(() => {
                this.setState({ loading: false });
                toast.error("Something went wrong, please try again later.");
            })
    }

    render() {
        let { loading, errors, message } = this.state;
        let { showNotiModal } = this.props;

        return (<>
            <div ref={this.myRef} />
            <Modal modalId="noti-modal"
              open={showNotiModal}
              onClose={this.closeModal}
              center
              container={this.myRef.current}>
              <div className="">
                    <div className="modal-body">
                        <div className="modal-header" style={{paddingLeft: '0'}}>
                            Send Notification to All
                        </div>
                        <Form errors={errors}>
                            <FormRow>
                                <div className="col-md-12" style={{width: '100%'}}>
                                    <Textarea name="message" rows={5} value={message}
                                        onChange={(name, value) => this.setState({ message: value })} maxlength = "255"/>
                                </div>
                            </FormRow>
                        </Form>
                    </div>
                    <div className="modal-footer">
                        {!loading? <button type="button" className="btn btn-signup" onClick={this.sendNotification}>
                            Send
                        </button>:
                        <LoadingButton className="btn btn-signup" />}
                    </div>
                </div>
            </Modal>
        </>)
    }
}
export default SendToAll;