import { useState, useEffect } from 'react';
import { Ajax } from '../../api';
import validate from '../../lib/validate'
import { isEmpty } from '../../helpers';
import { toast } from 'react-toastify';
import { getUser, removeUser } from './../../Auth/user';

export default function useForm(props, uploadInput) {
    var [ state, setState ] = useState({
        fields: {},
        errors: {},
        postLoading: false,
        isEdit: false,
        redirectTo: ''
    });

    useEffect(() => {
        if(props.match.params.id) {
            let params = {productId: props.match.params.id}
            Ajax.get('product/getproductbyid', params)
                .then(data => {
                    if(data.status && data.status === 'OK') {
                        setState(prev => ({...prev, fields: data.content, isEdit: true}));
                    }
                }).catch(e => e)
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = e => {
        e.preventDefault();
        let errors = validateForm();
        if(!isEmpty(errors)) {
            return false;
        }
        setState(prev => ({ ...prev, postLoading: true}))
        if(state.fields.newpass === state.fields.confirmpass){
            // sendValidateEmail()
            changePasswordApi();
        }else{
            toast.error("Confirm password is not same")
            setState(prev => ({ ...prev, postLoading: false}))
        }
    }

    function sendValidateEmail() {
        let params = {
            
        }
        Ajax.get('admin/validateotp', state.fields, 'GET')
            .then(data => {
                if(data.status && data.status === 'OK') {
                    toast.success(data.message)
                    console.log(data)
                    
                } else {
                    toast.error(data.message)
                }
                setState(prev => ({ ...prev, postLoading: false }))
            }).catch(() => {
                toast.error('Something went wrong, please try again.')
                setState(prev => ({ ...prev, postLoading: false}))
            })
    }

    function changePasswordApi() {
        const postBody = {
            "username": getUser()['username'],
            "password": state.fields.newpass
        }
        Ajax.call('admin/resetpassword', postBody)
            .then(data => {
                if(data.status && data.status === 'OK') {
                    toast.success(data.message)
                    setState(prev => ({ ...prev, postLoading: false}))
                    removeUser();
                    window.location.reload()
                    return
                } else {
                    toast.error(data.message)
                }
                setState(prev => ({ ...prev, postLoading: false}))
            }).catch(() => {
                toast.error('Something went wrong, please try again.')
                setState(prev => ({ ...prev, postLoading: false}))
            })
    }


    function validateForm() {
        let rules = {
            newpass: {required: true},
            confirmpass: {required: true}
        }
        let messages = {
            productName: {required: 'This field is required.'},
            cost: {required: 'This field is required.'}
        }
        let _fields = {...state.fields};
        let errors = validate(_fields, rules, messages);
        setState(prev => ({ ...prev, errors }))
        return errors;
    }

    /** handles the input field change event */
    const setFieldState = (name, value) => {
        let { fields, errors } = state;
        let _fields = { ...fields, [name]: value};
        let _errors = {...errors};
        delete _errors[name];
        setState(prev => ({ ...prev, errors: _errors, fields: _fields }));
    }

    const imageUpload = e => {
        let file = e.target.files[0];
        setState(prev => ({ ...prev, file }));
    }

    return {...state, setFieldState, imageUpload, handleSubmit };
}